<template>
  <v-container fluid>
    <ExciseDORLicReturns></ExciseDORLicReturns>
  </v-container>
</template>
<script>
import ExciseDORLicReturns from '@components/search/ExciseDORLicReturns'
export default {
  page: {
    title: 'Excise Admin',
  },
  components: {
    ExciseDORLicReturns,
  },
  data() {
    return {}
  },
  created() {},
  computed: {},
  methods: {},
  watch: {},
}
</script>
<style scoped></style>
