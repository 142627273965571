<template>
  <div>
      <v-row justify="end">
          <v-col sm="12" md="2">
              <BaseDatePickerWithText 
              label="Start Date" clearable 
              v-model="searchStartDate">
              </BaseDatePickerWithText>
          </v-col>
          <v-col sm="12" md="2">
              <BaseDatePickerWithText 
              label="End Date" 
              clearable v-model="searchEndDate">
              </BaseDatePickerWithText>
          </v-col>
          <v-col sm="12" md="2">
              <v-text-field v-model="searchLicenseNumber"
                  label="License Number"
                  clearable
              ></v-text-field>
          </v-col> 
          <v-col class="ml-auto" sm="12" md="2">
              <v-text-field v-model="searchText"
                  append-icon="mdi-magnify" 
                  label="Search"
                  clearable
                  hide-details
                  single-line
                  hint="EnvelopeID, LicenseNumber, ReturnType"
              ></v-text-field>
          </v-col> 
      </v-row>
      <!-- //templateTodo: if you use a custom search dto uncomment this to allow showing of filter params-->
      <!-- <v-row justify="center">
          <v-col sm="12" md="2">
              <h4 @click="toggleFilters" class="pointer primary--text">{{filterText}}
              </h4>
          </v-col>
      </v-row> -->
      <v-row v-if="filters">
          <!-- The Description and ID (item-text and item-value) 
          will need to be adjusted based on the properties in the array/lookup table
          these availableLists should be pulled from lookup tables and potentially
          filtered with a computed as desired (for IsActive status etc)
          These can also be switched to v-autocompletes if desired-->

      </v-row>
  </div>
</template>

<script>
    import { get, sync, commit, call } from 'vuex-pathify'
    export default {
        name: 'ExciseDorLicReturnsSearchParams',
        data: () => ({
            filters: false,
            filterText: '-Hide Filters-',
        }),
        components: {
        },
        created() {
        },
        watch: {
        },
        computed: {
        ...sync('exciseDorLicReturns', [
            'searchText',
            //templateTODO: uncomment as used in ExciseDORLicReturnsSearchParams
            'searchStartDate',
            'searchEndDate',
            'searchLicenseNumber',
        ]),
            //templateTODO: custom available lookup computed properties should go here
        },
        methods: {
            toggleFilters() {
                this.filters = !this.filters
                if (this.filters) {
                    this.filterText = '-Hide Filters-'
                } else {
                    this.filterText = '-Show Filters-'
                }
            },
        },
    }
</script>

<style lang="scss">

</style>