<template>
  <BasePanelWithHeader>
    <template v-slot:header>
      Excise DOR Returns
      <BaseTooltipButton
        v-if="canImport"
        icon="mdi-upload"
        iconColor="white"
        @click.stop="dialog = true"
      >
        Import LicReturns or RidReturns File
      </BaseTooltipButton>
    </template>
    <BaseServerSyncDataTable
      :headers="filteredHeaders"
      :items="exciseDORLicReturns"
      :searchText="searchText"
      :search="true"
      itemKey=""
      :loading="loading"
      :searchTotal="searchTotal"
      :searchSortBy.sync="searchSortBy"
      :searchDescending.sync="searchDescending"
      :searchPage.sync="searchPage"
      :searchRowsPerPage.sync="searchRowsPerPage"
    >
      <!-- //templateTodo: uncomment use custom search params-->
      <template v-slot:searchParams>
        <ExciseDORLicReturnsParams></ExciseDORLicReturnsParams>
      </template>
    </BaseServerSyncDataTable>
    <v-dialog
      persistent
      v-model="dialog"
      @keydown.esc="dialog = false"
      width="850"
      scrollable
    >
      <v-form ref="form" @submit.prevent>
        <v-card class="elevation-3">
          <v-card-title class="primary text-h5 white--text">
            Import
          </v-card-title>
          <v-card-text class="pa-4">
            <div>
              Import the LicReturns.txt or RidReturns.txt file provided by DOR
            </div>
            <div class="dropbox">
              <input class="input-file" type="file" @change="getFiles" />
              <p v-if="uploadList.length == 0" class="extra-margin">
                Drag file to upload or click to browse
              </p>
              <div v-if="uploadList.length > 0" class="extra-margin">
                <p
                  class="uploadList"
                  v-for="(item, index) in items"
                  :key="index"
                >
                  {{ item.name }}
                </p>
              </div>
            </div>
          </v-card-text>
          <v-card-actions>
            <v-btn @click="uploadDocument" :disabled="saving" color="primary"
              >Import</v-btn
            >
            <v-btn outlined color="primary" @click="dialog = false"
              >Cancel</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
  </BasePanelWithHeader>
</template>

<script>
import {get, sync, commit, call} from 'vuex-pathify'
//templateTodo: uncomment use custom search params
import ExciseDORLicReturnsParams from '@components/search/ExciseDORLicReturnsParams'
import {authComputed} from '@/src/state/helpers'
export default {
  name: 'ExciseDORLicReturnsSearchTable',
  components: {
    //templateTodo: uncomment use custom search params
    ExciseDORLicReturnsParams,
  },
  data: () => ({
    dialog: false,
    uploadList: [],
    items: [],
    saving: false,
  }),
  created() {
    this.searchExciseDORLicReturns()
  },
  computed: {
    ...authComputed,
    ...get('exciseDorLicReturns', ['exciseDORLicReturns', 'loading']),
    ...sync('exciseDorLicReturns', [
      'searchTotal',
      'searchPage',
      'searchSortBy',
      'searchDescending',
      'searchRowsPerPage',
      'searchText',
    ]),
    canImport() {
      let allowedRoles = ['Project Creator']
      return (
        this.currentUser.UserRole.filter((ur) => {
          return allowedRoles.includes(ur.Role.Description)
        }).length > 0
      )
    },
    headers() {
      return [
        {
          text: 'Form Number',
          align: 'center',
          sortable: true,
          value: 'FormNumber',
          visible: true,
        },
        {
          text: 'Envelope ID',
          align: 'center',
          sortable: true,
          value: 'EnvelopeID',
          visible: true,
        },
        {
          text: 'Page Number',
          align: 'center',
          sortable: true,
          value: 'PageNumber',
          visible: true,
        },
        {
          text: 'Posted Date',
          align: 'center',
          sortable: true,
          value: 'PostedDate',
          visible: true,
        },
        {
          text: 'Filing ID',
          align: 'center',
          sortable: true,
          value: 'FilingID',
          visible: true,
        },
        {
          text: 'Ownership RID',
          align: 'center',
          sortable: true,
          value: 'OwnershipRID',
          visible: true,
        },
        {
          text: 'License Number',
          align: 'center',
          sortable: true,
          value: 'LicenseNumber',
          visible: true,
        },
        {
          text: 'Report Period From',
          align: 'center',
          sortable: true,
          value: 'ReportPeriodFrom',
          visible: true,
        },
        {
          text: 'Report Period To',
          align: 'center',
          sortable: true,
          value: 'ReportPeriodTo',
          visible: true,
        },
        {
          text: 'Return Type',
          align: 'center',
          sortable: true,
          value: 'ReturnType',
          visible: true,
        },
        {
          text: 'Gross Sales And Taxable',
          align: 'center',
          sortable: true,
          value: 'GrossSalesAndTaxable',
          visible: true,
        },
        {
          text: 'Total Deductions',
          align: 'center',
          sortable: true,
          value: 'TotalDeductions',
          visible: true,
        },
        {
          text: 'Net Taxable Sales',
          align: 'center',
          sortable: true,
          value: 'NetTaxableSales',
          visible: true,
        },
        {
          text: 'Total Tax Due',
          align: 'center',
          sortable: true,
          value: 'TotalTaxDue',
          visible: true,
        },
        {
          text: 'Adjustments',
          align: 'center',
          sortable: true,
          value: 'Adjustments',
          visible: true,
        },
        {
          text: 'Total Due',
          align: 'center',
          sortable: true,
          value: 'TotalDue',
          visible: true,
        },
        {
          text: 'Total Sales Tax',
          align: 'center',
          sortable: true,
          value: 'TotalSalesTax',
          visible: true,
        },
        {
          text: 'Total Use Tax',
          align: 'center',
          sortable: true,
          value: 'TotalUseTax',
          visible: true,
        },
        {
          text: 'Lodging Sales',
          align: 'center',
          sortable: true,
          value: 'LodgingSales',
          visible: true,
        },
        {
          text: 'Lodging Tax Due',
          align: 'center',
          sortable: true,
          value: 'LodgingTaxDue',
          visible: true,
        },
      ]
    },
    filteredHeaders() {
      return this.headers.filter((h) => h.visible)
    },
  },
  methods: {
    handleError: call('errors/handleError'),
    dialogMessage: call('messages/dialogMessage'),
    searchExciseDORLicReturns: call(
      'exciseDorLicReturns/searchExciseDORLicReturns'
    ),
    getFiles: function (event) {
      this.uploadList = []
      this.items = []
      for (let file in event.target.files) {
        this.uploadList.push(event.target.files[file])
      }
      for (let i = 0; i < this.uploadList.length - 2; i++) {
        if (this.uploadList[i].name) this.items.push(this.uploadList[i])
      }
    },
    uploadDocument() {
      this.saving = true
      for (let i = 0; i < this.uploadList.length - 2; i++) {
        let file = this.uploadList[i]
        let formData = new FormData()
        formData.append('file', file)
        this.$axios
          .post(`/ExciseDORLicReturns/Import`, formData, {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          })
          .then((res) => {
            this.dialogMessage({message: res.data})
            this.searchExciseDORLicReturns()
            this.$refs.form.reset()
          })
          .catch((error) => {
            console.log(error)
            this.handleError(error)
            // this.error = error
            // this.displayError = true
          })
        // let fileReader = new FileReader()
        // if (fileReader && file) {
        //   fileReader.readAsDataURL(file)
        //   fileReader.onload = () => {
        //     let contents = fileReader.result.split(',')[1]
        //   }
        // }
      }
      this.saving = false
      this.dialog = false
    },
  },
}
</script>

<style scoped>
.dropbox {
  outline: 2px dashed rgb(190, 190, 190); /* the dash box */
  outline-offset: -10px;
  background: #fafafa;
  color: var(--v-accent-base);
  padding: 10px 10px;
  position: relative;
  cursor: pointer;
  height: 100px;
  margin-top: 10px;
}

.input-file {
  outline: 2px solid black;
  opacity: 0; /* invisible but it's there! */
  width: 100%;
  height: 100px;
  position: absolute;
  cursor: pointer;
}
</style>
